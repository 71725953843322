import React from 'react' 
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import { useWindowSize } from "../components/general/window";
import AudioPlayer from 'react-h5-audio-player';
import Video from "../components/general/video"
import 'react-h5-audio-player/lib/styles.css'; 

import Layout from '../components/layout/layout'
import Head from '../components/general/head'
import { PageContainer, DeepBackground, MediaContainer } from '../components/general/general' 
import { deviceMax } from "../styles/media"
import "@fontsource/raleway/700.css"
import "@fontsource/raleway/400.css"

const BannerImageBox = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
`;
const BannerImage = styled(props => <GatsbyImage {...props} />)`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    object-fit: cover;
    z-index: 1;
`;
const BannerShadow = styled.div`
    position: absolute;
    width: 100%;
    height: 5rem;
    margin-top: -5rem;
    z-index: 2;
    background: rgb(2,0,36);
    background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 100%);
`;
const DividerContainer = styled.div`
    position: relative;
    height: 15rem;
    margin-top: -3rem;
    z-index: 2;
`;
const DividerShape = styled.svg`
    width: 100%;
    height: 100%;
`;
const Slash1 = styled.path`
    fill: #1B2029;
    fill-opacity: 1;
    filter: url(#slash1);
`;
const Slash2 = styled.path`
    fill: #151921;
    fill-opacity: 1;
    filter: url(#slash2);
`;
const Background = styled(DeepBackground)`
    /* border: 2px solid cyan; */
    width: 100%;
    margin: -7rem 0 0;
    height: auto;
    padding-top: 5rem;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
`;
const Top = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Placeholder = styled.div`
    width: 100%;
    max-width: 1072px;
    /* padding-top: 18rem; */
    /* height: 30rem; */
    /* background: blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index:5;
    @media ${deviceMax.mobileL} { 
        /* border: 2px solid red; */
        padding: 0 1rem;
    }

`;

const From = styled.h1`
    text-transform: uppercase;
    font-family: 'Raleway';
    text-align: center;
    margin-top: 5rem;
    font-style: italic;
    @media ${deviceMax.tablet} { 
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }
    @media ${deviceMax.mobileL} { 
        font-size: 1rem;
        margin-bottom: 2rem;
    }
`;

const Logo = styled(props => <GatsbyImage {...props} />)`
    width: 60%;
    @media ${deviceMax.mobileL} {
        width: 90%;
    }    
`;

const CustomAudioPlayer = styled(AudioPlayer)`
    visibility: hidden;
    background-color: #0F132E;
    border: 1px solid #4E7187;
    border-radius: 5px;
    font-family: "Raleway";
    width: 60%;
    margin: 2rem;
    filter: drop-shadow(0px 20px 20px rgba(0,0,0,1));

    @media ${deviceMax.mobileL} {
        display: none;
        width: 100%;
    }
    
    .rhap_time {
        color: #FFF;
    }
    .rhap_main-controls-button {
        color: #FFF;
    }
    .rhap_progress-indicator {
        background: #FFF;
    }
    .rhap_progress-filled {
        background-color: #4E7187;
    }
    .rhap_controls-section {
        display: flex;
        flex: 0 1 auto;
        justify-content: space-between;
        align-items: center;
    }
`;
const Canvas = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    /* border: 2px solid red; */
    /* padding: 20rem 0 0rem; */
    @media ${deviceMax.laptopL} { 
        /* padding: 12rem 0 0rem; */
    }
    @media ${deviceMax.laptop} { 
        /* padding: 10rem 0 0rem; */
        margin-bottom: 5rem;
    }
    @media ${deviceMax.tablet} { 
        /* padding: 5rem 0 0rem; */
    }
    @media ${deviceMax.mobileL} { 
        /* padding: 0rem 0 5rem; */
    }
`;
const Row = styled.div`
    width: 100%;
    max-width: 1600px;
    /* border: 2px solid orange; */
    margin: -15rem 2rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => props.direction};
    @media ${deviceMax.laptopL} { 
        margin: -12rem 2rem 0;
    }
    @media ${deviceMax.laptop} { 
        margin: -10rem 2rem 0;
    }
    /* @media ${deviceMax.tablet} { 
        margin: -5rem 1rem 0;
    } */
    @media ${deviceMax.tablet} { 
        margin: 0 1rem 0rem;
        flex-direction: column;
        align-items: center;
    }
`;


const Element = styled(props => <GatsbyImage {...props} />)`
    /* position: absolute; */
    width: 18rem;
    margin: 5rem;
    border: 2px solid ${props => props.color};
    border-radius: 5px;
    top: ${props => props.top};
    bottom: ${props => props.bottom};
    left: ${props => props.left};
    right: ${props => props.right};

    @media ${deviceMax.laptopL} { 
        width: 16rem;
    }
    @media ${deviceMax.laptop} { 
        width: 12rem;
    }
    @media ${deviceMax.tablet} { 
        width: 20rem;
        margin: 2rem 0 0; 
    }
    /* @media ${deviceMax.mobile} { 
        width: 0rem 0 0rem;
    } */
    
`;

const ComicPanel = styled(props => <GatsbyImage {...props} />)`
    /* width: 35%; */
    width: 25rem;
    /* height: 16rem; */
    /* border: 2px solid yellow; */
    /* z-index: 1; */
    border: 2px solid ${props => props.color};
    border-radius: 5px;
    filter: drop-shadow(0px 0px 50px ${props => props.altcolor});
    @media ${deviceMax.laptopL} {
        width: 22rem;
    }
    @media ${deviceMax.laptop} { 
        width: 20rem;
    }
    @media ${deviceMax.tablet} { 
        margin: 0 !important;
        margin-top: 2rem !important;
    }
`;
const Samantha = styled(ComicPanel)`
    /* position: relative; */
    margin-left: 25%;
`;
const James = styled(ComicPanel)`
    /* float: right; */
    /* left: 65%; */
    /* margin: 0 0 0 50%;  */
    margin-right: 5%;
`;
const Rosa = styled(ComicPanel)`
    /* left: 15%; */
    margin-left: 15%;
`;
const Gabby = styled(ComicPanel)`
    margin-right: 10%;
    /* left: 55%; */
`;
const Joshua = styled(ComicPanel)`
    /* left: 5%; */
    margin-left: 5%;
`;
const Isabella = styled(ComicPanel)`
    /* left: 50%; */
    margin-right: 20%;
`;
const Learn = styled.div`
    width: 100%;
    height: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(2,0,36);
    background: linear-gradient(0deg, #140F29 0%, #1B2029 100%);
`;
const Warning = styled.div`
    text-align: center;
    font-family: "Raleway";
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;

    width: 75%;
    margin: 3rem 0;
    @media ${deviceMax.tablet} {
        font-size: 1rem;
        /* padding: 1rem 0; */
    }
`;

const BigButton = styled.div`
    margin: 0 1rem;
    color: #FFF;
    background-color: #0F132E;
    border: 1px solid #4E7187;
    border-radius: .5rem;
    font-family: "Raleway";
    font-weight: 700;
    font-size: 2rem;
    padding: 1.5rem;
    box-shadow: 0px 5px 20px #000;
    cursor: pointer;
    transition: .5s ease-out;

    &:hover {
        transition: .3s ease-in;
        transform: scale(1.01);
        color: $avenger-yellow;
        border-color: #b9d2e2;
        box-shadow: 0px 10px 30px #000;
    }
    @media ${deviceMax.tablet} {
        font-size: 1rem;
        margin-bottom: 2rem;
    }
`;

const TeaseWrap = styled.div`
    width: 100%;
    margin-top: -10rem;
    margin-bottom: -10rem;
    display: flex;
    justify-content: center;
    /* border: 2px solid red; */
    z-index: 10;
    
`

const Thing = styled.div`
    max-width: 35rem;
    width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
    border: 2px solid #FAA62A;
    border-radius: 2px;
    z-index: 6;
    /* height: 5em; */
    filter: drop-shadow(0px 0px 50px rgba(250, 166, 42, .5));
    
`

const IndexPage = (props) => {

    // const data = useStaticQuery( graphql`
    // query {
    //     contentfulAsset (title: {eq: "Static Banner"}){
    //         title
    //         description
    //         gatsbyImageData (
    //             layout: FULL_WIDTH
    //             placeholder: DOMINANT_COLOR
    //             formats: [AUTO, WEBP]
    //         )
    //     }
    //     contentfulMessage {
    //         messageComponents {
    //             comicPanel {
    //                 gatsbyImageData
    //             }
    //         }
    //     }
    // }
    // `)

    const group = props.data.contentfulMessage.messageComponents

    let window = useWindowSize()
    const width  = window[0]

    return (
        <Layout>
            <Head title="Home" />
                <PageContainer>
                    <BannerImageBox>
                        <BannerImage
                            image={props.data.imageAsset.gatsbyImageData}
                            alt={props.data.imageAsset.description}
                        />
                        <BannerShadow />
                    </BannerImageBox>
                    <TeaseWrap>
                        <Thing>
                                <Video
                                    videoSrcURL="https://player.vimeo.com/video/611658064"
                                    videoTitle="Test"
                                    // ?color=FAA62A&title=0&byline=0&portrait=0
                                />
                            </Thing>
                    </TeaseWrap>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    <DividerContainer>
                        <DividerShape 
                             xmlns="http://www.w3.org/2000/svg" 
                             viewBox="0 0 100 125" preserveAspectRatio="none">
                            <defs>
                            <filter id="slash1" x="0" y="0" width="200%" height="200%">
                                <feOffset result="offOut" in="SourceAlpha" dx="0" dy="2" />
                                <feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />
                                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                            </filter>
                            <filter id="slash2" x="0" y="0" width="200%" height="200%">
                                <feOffset result="offOut" in="SourceAlpha" dx="0" dy="2" />
                                <feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />
                                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                            </filter>

                            </defs>
                            <Slash2 d="M0,75L100,100L100,0L0,30Z" />
                            <Slash1 d="M0,75L100,100L100,50L0,0Z" />
                        </DividerShape>
                        
                           
                    </DividerContainer>
                    
                <ParallaxProvider>
                    <Background>
                    
                    
                        <Top>
                        
                            <Placeholder>
                                <From>
                                    Meet the Trauma Avengers Team!<br/><br/>
                                    Learn how to use our films and website
                                    to improve your mental health and defeat the demons!
                                </From>
                                {/* <Logo 
                                    image={props.data.logoAsset.gatsbyImageData}
                                    alt={props.data.logoAsset.description}
                                /> */}
                                <CustomAudioPlayer 
                                    src={props.data.audioAsset.file.url}
                                    layout="horizontal-reverse"
                                    customVolumeControls={[]}
                                    customAdditionalControls={[]}
                                    showSkipControls={false}
                                    showJumpControls={false}                            
                                />
                            </Placeholder>
                        </Top>
                        <Parallax
                            y={[10, -10]}
                            fasterScrollRate
                            disabled={width <= 1024 }
                            // disabled={width <= 1024 }
                        >  
                        <Canvas>
                            <Row direction="flex-start">
                                <Samantha 
                                    image={group[0].comicPanel.gatsbyImageData}
                                    color={group[0].colors.highlight}
                                    altcolor={group[0].colors.lowlight}
                                    // alt={story.primaryImage.description}                            
                                />
                                <Parallax
                                    y={[20, -20]}
                                    slowerScrollRate
                                    disabled={width <= 1024 }
                                > 
                                    <Element 
                                        image={group[0].image1.gatsbyImageData}
                                        color={group[0].colors.highlight}
                                        // alt={story.primaryImage.description}
                                        top="0%"                            
                                    />
                                </Parallax>
                            </Row>
                            <Row direction="flex-end">
                                <Parallax
                                    y={[20, -20]}
                                    slowerScrollRate
                                    disabled={width <= 1024 }
                                > 
                                    <Element 
                                        image={group[1].image1.gatsbyImageData}
                                        color={group[1].colors.highlight}
                                        // alt={story.primaryImage.description}
                                        top="0%"
                                        left="0%"                            
                                    />
                                </Parallax>
                                <James
                                    image={group[1].comicPanel.gatsbyImageData}
                                    color={group[1].colors.highlight}
                                    altcolor={group[1].colors.primary}
                                    // alt={story.primaryImage.description}                            
                                />
                            </Row>
                            <Row direction="flex-start">
                                <Rosa 
                                    image={group[2].comicPanel.gatsbyImageData}
                                    color={group[2].colors.highlight}
                                    altcolor={group[2].colors.lowlight}
                                    // alt={story.primaryImage.description}                            
                                />
                                <Parallax
                                    y={[20, -20]}
                                    slowerScrollRate
                                    disabled={width <= 1024 }
                                > 
                                    <Element 
                                        image={group[2].image1.gatsbyImageData}
                                        color={group[2].colors.highlight}
                                        // alt={story.primaryImage.description}
                                        top="0%"
                                        left="0%"                            
                                    />
                                </Parallax>
                                
                            </Row>
                            <Row direction="flex-end">
                            <Parallax
                                    y={[20, -20]}
                                    slowerScrollRate
                                    disabled={width <= 1024 }
                                > 
                                    <Element 
                                        image={group[3].image1.gatsbyImageData}
                                        color={group[3].colors.highlight}
                                        // alt={story.primaryImage.description}
                                        top="0%"
                                        left="0%"                            
                                    />
                                </Parallax> 
                                <Gabby 
                                    image={group[3].comicPanel.gatsbyImageData}
                                    color={group[3].colors.highlight}
                                    altcolor={group[3].colors.lowlight}
                                    // alt={story.primaryImage.description}                            
                                />
                                
                            </Row>
                            <Row direction="flex-start">
                                <Joshua 
                                    image={group[4].comicPanel.gatsbyImageData}
                                    color={group[4].colors.highlight}
                                    altcolor={group[4].colors.lowlight}
                                    // alt={story.primaryImage.description}                            
                                />
                                <Parallax
                                    y={[20, -20]}
                                    slowerScrollRate
                                    disabled={width <= 1024 }
                                > 
                                    <Element 
                                        image={group[4].image1.gatsbyImageData}
                                        color={group[4].colors.highlight}
                                        // alt={story.primaryImage.description}
                                        top="0%"
                                        left="0%"                            
                                    />
                                </Parallax>
                                
                            </Row>
                            <Row direction="flex-end">
                            <Parallax
                                    y={[20, -20]}
                                    slowerScrollRate
                                    disabled={width <= 1024 }
                                > 
                                    <Element 
                                        image={group[5].image1.gatsbyImageData}
                                        color={group[5].colors.highlight}
                                        // alt={story.primaryImage.description}
                                        top="0%"
                                        left="0%"                            
                                    />
                                </Parallax>
                                <Isabella 
                                    image={group[5].comicPanel.gatsbyImageData}
                                    color={group[5].colors.highlight}
                                    altcolor={group[5].colors.lowlight}
                                    // alt={story.primaryImage.description}                            
                                />
                                
                            </Row>
                        </Canvas>
                        </Parallax>
                    </Background>
                    {/* </Parallax> */}
                    
                </ParallaxProvider> 
                <Learn>
                    <Link to="/characters" style={{ textDecoration: 'none' }}><BigButton>LEARN MORE ABOUT THE TRAUMA AVENGERS</BigButton></Link>
                    
                    <Warning>
                        The teen characters and their filmed “Digital Diaries” are created by therapists and a creative 
                        team, drawn from actual therapy cases. While they contain some adult language and deal with 
                        emotional experiences, the films are intended for adolescent and teen viewers, preferably under 
                        the guidance of a therapist.
                    </Warning>
                </Learn>
            </PageContainer>
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
    query {
        imageAsset: contentfulAsset (title: {eq: "Static Banner"}){
            title
            description
            gatsbyImageData (
                layout: FULL_WIDTH
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
            )
        }
        logoAsset: contentfulAsset (title: {eq: "logo-simple-yellow"}){
            title
            description
            gatsbyImageData (
                layout: FULL_WIDTH
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
            )
        }
        audioAsset: contentfulAsset (title: {eq: "Avengers Message"}){
            file {
                url
            }
        }
        contentfulMessage {
            messageComponents {
                comicPanel {
                    gatsbyImageData
                }
                image1 {
                    gatsbyImageData
                }
                image2 {
                    gatsbyImageData
                }
                image3 {
                    gatsbyImageData
                }
                colors {
                    primary
                    highlight
                    lowlight

                }
            }
        }
    }
`