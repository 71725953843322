import React from "react"
import styled from "styled-components";

const VideoContainer = styled.div`
    padding:56.25% 0 0 0;
    position:relative;
    border-radius: 5px 5px 0 0;
`;

const VideoFrame = styled.iframe`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    border-radius: 5px 5px 0 0;
`;

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <VideoContainer>
    <VideoFrame
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
    <script src="https://player.vimeo.com/api/player.js"></script>
  </VideoContainer>
    
//   <script src="https://player.vimeo.com/api/player.js"></script>
)
export default Video